<template>
  <div class="WorkticketCheckGas has-nav-bar">
    <WorkticketHeader title="气体检测"></WorkticketHeader>
    <div>
      <van-search
        v-model="query.workName"
        class="search-bar__workticket"
        placeholder="请输入所属作业名称或编号"
        @search="getList(true)"
        @clear="getList(true)"
      />
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="date"
        format="{y}-{m}-{d}"
        @input="getList(true)"
      />
      <work-query
        :query="query"
        query-type="gas"
        @refresh="onQueryChange"
      ></work-query>
    </div>
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      @load="getList"
    >
      <WorkticketGasCheckItem
        v-for="(item, index) in list"
        :id="item.id"
        :key="index"
        :class="{ 'block-space_8': index < list.length - 1 }"
        :title="item.ticketName"
        :types="item.workTypeName"
        :number="item.ticketCode"
        :text1="item.departmentName"
        :text2="item.poiName"
        :text3="item.uuserName"
        :text4="item.utime"
        :result="item.result"
      />
    </LoadListView>
  </div>
</template>

<script>
import WorkticketHeader from "@/components/header/WorkticketHeader";
import WorkticketGasCheckItem from "@/components/workticket/WorkticketGasCheckItem";
import LoadListView from "@/components/LoadListView";
import { getWorkGasMonitorList } from "@/api/workticket";
import WorkQuery from "@/views/workticket/components/WorkQuery";

export default {
  name: "WorkticketCheckGas",
  components: {
    WorkQuery,
    LoadListView,
    WorkticketGasCheckItem,
    WorkticketHeader
  },
  data() {
    return {
      loadStatus: "success",
      dateTime: {
        start: "",
        end: ""
      },
      list: [],
      total: 0,
      query: {
        size: 10,
        page: 1,
        queryTimeStart: "",
        queryTimeEnd: "",
        workType: "", //作业类别
        department: "", //部门
        poiId: "", //作业区域
        result: "", //检测结果
        workName: "",
        sort: "utime_desc"
      },
      filterLabel: {
        department: "",
        area: "",
        type: "",
        result: ""
      },
      departmentVisible: false
    };
  },
  computed: {
    workTypeOptions: {
      get() {
        return this.$store.state.workticket.workTypeOptions;
      }
    },
    userInfo() {
      return this.$store.state.login.userInfo;
    }
  },
  created: function() {},
  mounted: function() {
    this.getList(true);
    this.$store.dispatch("getWorkTypeOptions");
  },
  methods: {
    onQueryChange(query) {
      this.query = { ...this.query, ...query };
      this.getList(true);
    },
    /**
     * 是否是刷新,重新加载第一页
     * @param refresh
     */
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.loadStatus = "loading";
      }

      getWorkGasMonitorList({
        ...this.query,
        orgCode: this.userInfo.orgCode,
        startTime: this.dateTime.start && this.dateTime.start + " 00:00:00",
        endTime: this.dateTime.end && this.dateTime.end + " 23:59:59"
      })
        .then(({ total, list }) => {
          this.list = this.list.concat(list);
          this.total = total;
          this.loadStatus = "success";
          if (list && list.length > 0) {
            this.query.page++;
          }
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
